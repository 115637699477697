import { FC } from 'react';
import Link from 'next/link';
import styles from 'styles/components/ExplorePanel.module.scss';

const ExplorePanel: FC<{ label: string; image: string; labelType: 'tag' | 'sort' }> = ({
	label,
	image,
	labelType = 'tag',
}) => {
	const fixLabel = (label: string) => {
		if (label === 'Seasonal') {
			const d = new Date();
			const thisMonth = d.getMonth();
			if (thisMonth === 11 || thisMonth < 2) {
				return 'Winter';
			} else if (thisMonth < 5) {
				return 'Spring';
			} else if (thisMonth < 8) {
				return 'Summer';
			} else {
				return 'Autumn';
			}
		}
		return label;
	};

	const tag = encodeURIComponent(JSON.stringify([fixLabel(label)]));

	return (
		<Link href={labelType === 'sort' ? `explore?sort=${label}` : `explore?tags=${tag}`}>
			<a className={styles.container} style={{ backgroundImage: `url(${image})` }}>
				<span className={styles.label}> Explore: {label} </span>
			</a>
		</Link>
	);
};

export default ExplorePanel;
