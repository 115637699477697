import { useEffect, useState, FC } from 'react';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { db } from 'lib/firebase';
import Link from 'next/link';
import { BlogPost } from 'types/BlogPost';
import styles from 'styles/components/HomeCarousel.module.scss';
import useSWR from 'swr';
import { fetcher } from 'lib/fetcher';
import { blog } from '@prisma/client';

const HomeCarousel: FC = () => {
	const [carouselItems, setCarouselItems] = useState<any[] | null>(null);
	const { data: posts } = useSWR(
		[
			'/api/blogs',
			{
				where: {
					show_to_customers: true,
				},
				orderBy: { created: 'desc' },
				take: 2,
			},
		],
		fetcher
	);

	useEffect(() => {
		if (!posts) return;

		const items: any = [];
		posts.forEach((post: blog) => {
			items.push({ ...post, type: 'Blog', link: `/blog/${post.id}` });
		});

		setCarouselItems(items);
	}, [posts]);

	return (
		<div className={styles.container}>
			<button className={styles.left}>
				<ArrowBackIosRounded fontSize='large' />
			</button>

			{carouselItems && carouselItems[0] ? (
				<Link href={carouselItems[0].link}>
					<a
						className={styles.content}
						style={{
							backgroundImage: `url(${carouselItems[0].image})`,
							backgroundSize: 'cover',
						}}>
						<span className={styles.featured}>{carouselItems[0].type}</span>
						<span className={styles.info}>{carouselItems[0].title}</span>
					</a>
				</Link>
			) : (
				<div className={styles.content} />
			)}

			<button className={styles.right}>
				<ArrowForwardIosRounded fontSize='large' />
			</button>
		</div>
	);
};

export default HomeCarousel;
