import useSWR from 'swr';
import axios from 'axios';
import { Product } from 'types';
import { fetcher } from 'lib/fetcher';

const useProducts = () => {
	const { data, error } = useSWR(`/api/me/products`, fetcher);

	return {
		products: data as Product[],
		isLoading: !error && !data,
		isError: error,
	};
};

export default useProducts;
