import { FC } from 'react';
import ExplorePanel from './ExplorePanel';
import Link from 'next/link';
import styles from 'styles/components/ExploreSection.module.scss';
import { logError } from 'lib/functions/errors';
import useSWR from 'swr';
import { fetcher } from 'lib/fetcher';

const ExplorePanels: FC = () => {
	const { data, error } = useSWR('/api/products/featured-products', fetcher);

	if (error) {
		logError(error);
		return null;
	}

	let ipaProduct, lagerProduct, seasonalProduct, newProduct;
	if (data) {
		({ ipaProduct, lagerProduct, seasonalProduct, newProduct } = data);
	}

	return (
		<>
			<div className={styles.container}>
				<ExplorePanel
					image={ipaProduct ? ipaProduct.image : ''}
					label={`IPA`}
					labelType='tag'
				/>
				<ExplorePanel
					image={lagerProduct ? lagerProduct.image : ''}
					label={`Lager`}
					labelType='tag'
				/>
				<ExplorePanel
					image={newProduct ? newProduct.image : ''}
					label={`New`}
					labelType='sort'
				/>
				<ExplorePanel
					image={seasonalProduct ? seasonalProduct.image : ''}
					label={`Seasonal`}
					labelType='tag'
				/>
			</div>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Link href='/explore'>
					<button className='blueButton'>Explore Products</button>
				</Link>
			</div>
		</>
	);
};

export default ExplorePanels;
