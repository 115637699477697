import { FC } from 'react';
import Link from 'next/link';
import styles from 'styles/components/StorefrontHeroPanel.module.scss';
import useSWR from 'swr';
import { fetcher } from 'lib/fetcher';

const StorefrontHeroPanel: FC = () => {
	const { data: blog } = useSWR(
		[
			'/api/blogs',
			{
				where: {
					show_to_storefronts: true,
				},
				orderBy: { created: 'desc' },
				take: 1,
			},
		],
		fetcher
	);

	if (!blog) return <div className={styles.container}></div>;

	const [post] = blog;

	return (
		<Link href={`/blog/${post.id}`}>
			<a className={styles.container} style={{ backgroundImage: `url(${post.image})` }}>
				<span>{`Blog: ${post.title}`}</span>
			</a>
		</Link>
	);
};

export default StorefrontHeroPanel;
