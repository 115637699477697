import { InfoRounded, WarningRounded } from '@mui/icons-material';
import { FC } from 'react';
import Link from 'next/link';

/**
 *
 * @param {String} alertType 'info', 'warning', 'danger'
 * @param {String} text text to display in the alert
 * @param {String} boldText header text to display in the alert (optional)
 * @param {String} link a link target for the alert (optional)
 * @param {String} onClick an action when the banner is clicked (optional / required if no link)
 *
 */
const BannerAlert: FC<{
	alertType?: string;
	boldText?: string | null;
	text: string;
	link?: string | null;
	onClick?: () => void;
	full?: boolean;
}> = ({
	alertType = 'info',
	boldText = null,
	text = '',
	link = null,
	onClick = () => {},
	full = false,
}) => {
	if (link) {
		return (
			<Link href={link}>
				<div className={`bannerAlert--${alertType}`} style={full ? { width: '100%' } : {}}>
					{alertType === 'info' ? <InfoRounded fontSize='large' /> : null}
					{alertType === 'warning' ? <WarningRounded fontSize='large' /> : null}
					{alertType === 'danger' ? <WarningRounded fontSize='large' /> : null}
					<div>
						{boldText ? <strong>{boldText}</strong> : null}
						<span>{text}</span>
					</div>
				</div>
			</Link>
		);
	}

	return (
		<div
			className={`bannerAlert--${alertType}`}
			onClick={onClick}
			style={full ? { width: '100%' } : {}}>
			{alertType === 'info' ? <InfoRounded fontSize='large' /> : null}
			{alertType === 'warning' ? <WarningRounded fontSize='large' /> : null}
			{alertType === 'danger' ? <WarningRounded fontSize='large' /> : null}
			<div>
				{boldText ? <strong>{boldText}</strong> : null}
				<span>{text}</span>
			</div>
		</div>
	);
};

export default BannerAlert;
