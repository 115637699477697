import type { NextPage } from 'next';
import Link from 'next/link';
import {
	Store,
	Receipt,
	Settings,
	ContactSupport,
	SupervisorAccountRounded,
} from '@mui/icons-material';
import { useContext } from 'react';
import { UserContext } from 'lib/context/UserContext';
import HomeCarousel from 'components/Home/Carousel/HomeCarousel';
import ExploreSection from 'components/Home/Explore/ExploreSection';
import StorefrontHeroPanel from 'components/Home/StorefrontHeroPanel';
import useScrollToTop from 'lib/hooks/useScrollToTop';
import { UserContextType } from 'types';
import Page from 'components/Common/Page';
import styles from 'styles/pages/Home.module.scss';
import useProducts from 'lib/hooks/data/useProducts';
import BannerAlert from 'components/Common/BannerAlert';

const Home: NextPage = () => {
	useScrollToTop();
	const { products, isLoading: productsLoading } = useProducts();
	const { userLoading, user } = useContext(UserContext) as UserContextType;

	if (!user) return null;

	return (
		<Page className={styles.container} title='OrderSimply'>
			{!productsLoading && products.length === 0 && user.type === 'storefront' && (
				<div className='basicFlex--column' style={{ marginBottom: '3rem' }}>
					<BannerAlert
						link='/product/add'
						boldText='Get started by adding products'
						text='Click here to create your first product'
						alertType='info'
					/>
				</div>
			)}
			{user.admin && <div style={{ margin: '0 0 50px', fontWeight: 600 }}>Bar Homepage</div>}
			{(user.type === 'customer' || user.admin) && (
				<>
					<HomeCarousel />
					<ExploreSection />
				</>
			)}
			{user.admin && (
				<div style={{ margin: '100px 0 50px', fontWeight: 600 }}>Brewery Homepage</div>
			)}
			{(user.type === 'storefront' || user.admin) && (
				<>
					<StorefrontHeroPanel />
					{/* <Telemetry /> */}
				</>
			)}
			{userLoading && (
				<>
					{/* todo : create placeholders while loading */}
					<div style={{ height: '400px' }}></div>
				</>
			)}
			<div className={styles.links}>
				{user.admin ? (
					<Link href='/admin'>
						<div className={styles.link}>
							<div className={styles.icon}>
								<SupervisorAccountRounded fontSize='large' />
							</div>
							<div className={styles.info}>
								<h3>Admin</h3>
								<span>{user.email}</span>
							</div>
						</div>
					</Link>
				) : (
					<Link href='/account/overview'>
						<div className={styles.link}>
							<div className={styles.icon}>
								<Store fontSize='large' />
							</div>
							<div className={styles.info}>
								<h3 className='home__panels__panel__title'>My Account</h3>
								<span>{user.profile ? user.profile.name : user.email}</span>
							</div>
						</div>
					</Link>
				)}

				{!user.admin && (
					<>
						<Link href='/orders'>
							<div className={styles.link}>
								<div className={styles.icon}>
									<Receipt fontSize='large' />
								</div>
								<h3 className='home__panels__panel__title'>
									{user && user.type === 'storefront'
										? 'Orders & Invoices'
										: 'Orders & Receipts'}
								</h3>
							</div>
						</Link>
						<Link href='/account/settings'>
							<div className={styles.link}>
								<div className={styles.icon}>
									<Settings fontSize='large' />
								</div>
								<h3 className='home__panels__panel__title'>Settings</h3>
							</div>
						</Link>
					</>
				)}

				<Link href='/customer-support'>
					<div className={styles.link}>
						<div className={styles.icon}>
							<ContactSupport fontSize='large' />
						</div>
						<h3 className='home__panels__panel__title'>Customer Support</h3>
					</div>
				</Link>
			</div>
		</Page>
	);
};

export default Home;
